<template>

  <v-row no-gutters>
    <v-col>
      <div id="expense_add">
          <Image-upload
          v-model="isUploadExpenseImageActive"
          :data="dataUpload"
          :isUploadImageModul="modul"
          :isActive="image_isActive"
          :isImageSize="imageSize"
          @save-image-event="saveImageEvent"          
          />

          <v-snackbars :objects.sync="snackbars" width="300" top right transition="slide-y-transition">
            <template v-slot:action="{ close }">
              <v-btn icon small @click="close()">
                <v-icon small>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </template>
          </v-snackbars>

        <v-card tile :flat="$vuetify.breakpoint.mdAndDown" :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'">
          <v-toolbar :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87" color="transparent" flat>

          <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
              <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                {{ icons.mdiCalculatorVariant }}
              </v-icon>
              Yeni Masraf Girişi
          </v-toolbar-title>
          <v-spacer></v-spacer>             
     
          </v-toolbar>
          <v-divider></v-divider>
         
              <!-- table  -->
          <v-data-table    
              v-model="selectedRows"
              :headers="tableColumns"
              :items="expenseData"      
              :items-per-page="5"
              dense
              :loading="loading"
            >
              <!-- Text "expenseType"     -->
             
              
              <template #[`item.expense_date`]="{ item }">                
                <v-row no-gutters align="center" justify="start">
                  <v-col cols="12">
                    <v-menu                   
                    v-model="item.dateMenu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"   
                  >
                       <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                          v-model="item.expense_date"                                        
                          persistent-hint
                          :prepend-icon="icons.mdiCalendar"                     
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                       </template>
            
                      <v-date-picker
                        v-model="date"
                        no-title
                        color="primary"
                        @input="item.expense_date = parseDate(computedDateFormatted)"
                        
                      ></v-date-picker>   
                                      
                  </v-menu>                  
                  </v-col>                 
                </v-row>
              </template>
            
              <template v-slot:[`item.expense_type`]="{ item }">   
               <v-row no-gutters align="center" justify="start">
                <v-col cols="12">       
                  <v-select
                  color="secondary"
                  v-model="item.expense_type"  
                  :items="expenseTypeItems"
                  hide-details   
                  dense
                  clearable
                  ></v-select>
                </v-col>                 
              </v-row>
              </template>

              <template #[`item.expense_amount`]="{ item }"> 
                <v-row no-gutters align="center" justify="start">
                  <v-col cols="12">                                       
                      <VCurrencyField 
                      v-model="item.expense_amount" 
                      :options="{ currency: 'TRY'}" 
                      @bosDeger= gelen($event) 
                      :ilkDeger=true    
                      @change="onChange1(item.expense_amount)"
                        />  
                  </v-col>                 
                </v-row> 
              </template>

              <template #[`item.expense_desc`]="{ item }">       
                <v-row no-gutters align="center" justify="start">
                  <v-col cols="12">               
                  <v-text-field
                      color="secondary"
                      v-model="item.expense_desc"
                      hide-details 
                      clearable
                      dense                      
                    ></v-text-field>
              </v-col>                 
            </v-row> 
              </template>

              <template #[`item.actions`]="{ item }">
                
                <v-tooltip 
                bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      color="nightDark"
                      x-small
                      class="rounded-l-0 rounded-r-xl"
                      v-bind="attrs"
                      @click="expenseImageUpload(item)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiCamera }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Resim Yükle</span>
                </v-tooltip>

                <v-tooltip bottom color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed color="error" x-small plain v-bind="attrs"  @click="removeRow(item)" v-on="on">
                      <v-icon small>
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Sil</span>
                </v-tooltip>

              </template>
          </v-data-table>

          <v-card-actions class="d-flex justify-space-between">       
              <v-btn
              @click="addRow"
              fab
              dark
              color="error"
            >
              <v-icon dark>
                {{ icons.mdiPlus }}
              </v-icon>
              </v-btn>

              <v-btn  v-if="!kaydetDurum" 
              rounded
              x-large    
              @click="addData"  
              :class="{
                'bg-gradient-nightDark': !isDark,
                'bg-gradient-primary': isDark, // isDark ise farklı bir stil uygula
                'text-capitalize ms-3 white--text': true,     
              }"

              :disabled="kaydetDurum" 
              @mouseenter="activeHover = true" @mouseleave="activeHover = false"
              >
                Kaydet
                <span  v-if="activeHover"> <img src="@/assets/images/misc/tukas-yaprak.png" width="20" > </span>   
          </v-btn>
          </v-card-actions>

        </v-card>
      </div>
    </v-col>
  </v-row>
   
</template>
  <script src="https://code.jquery.com/jquery-3.7.0.js"></script>
  <script>
  import moment from 'moment';
  import router from '@/router'
  import VSnackbars from 'v-snackbars'
  import ImageUpload from '@/components/ImageUpload.vue'
  import expenseStoreModule from '@/views/expense/expenseStoreModule'
 
  import VCurrencyField from '@/views/expense/currency/VCurrencyField'
  import useAppConfig from '@core/@app-config/useAppConfig'
  import { currency } from '@utils'
  import store from '@/store'
  import {
  mdiFactory,
  mdiCamera,
  mdiCameraOff,  
  mdiDelete,
  mdiEye,  
  mdiSquareEditOutline,
  mdiPlus,
  mdiCollage,
  mdiCalendar,
  mdiCalculatorVariant,
  mdiClose
} from '@mdi/js'
  import { onMounted,onUnmounted, ref ,watch,computed} from '@vue/composition-api'
  import Vue from 'vue'
  export default {
    components: {
    ImageUpload  ,
    VSnackbars,  
    VCurrencyField
},
  setup() {
    const { isDark } = useAppConfig()
    const EXPENSE_APP_STORE_MODULE_NAME = 'app-expense'
    const periodDeger = ref()
    const periodItems = ref([])
    const expenseListTable = ref([])
    const selectedRows = ref([])
    const totalEventListTable = ref(0)
    const loading = ref(false)
    const expenseType = ref()
    const expenseTypeItems = ref([])
    const kaydetDurum = ref(true)
    const dataUpload = ref({})
    const isUploadExpenseImageActive = ref(false)
    const masrafTutariKontrol = ref(0)
    const masrafTipiKontrol = ref(0)
    const date = ref(new Date().toISOString().substr(0, 10))//
    const menu1 = ref(false)
    const snackbars = ref([])
    const price =ref(0)
    const rowIndex =ref(0) 
    const changePrice=ref(false)  
   
    //#region DATE FORMAT
    const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')
      return `${day}-${month}-${year}`
    }
    let dateFormatted = formatDate(new Date().toISOString().substr(0, 10))

    const parseDate = dates => {
      if (!dates) return null
      const [day,month, year] = dates.split('-')
      return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
    }

    const computedDateFormatted = computed(() => formatDate(date.value)) 

  /*   watch(date, () => {
      let dateFormatted = formatDate(date.value)
    })   */

    //#endregion
   

   // Register module
   if (!store.hasModule(EXPENSE_APP_STORE_MODULE_NAME)) {
      store.registerModule(EXPENSE_APP_STORE_MODULE_NAME, expenseStoreModule)
   }
   // UnRegister on leave
   onUnmounted(() => {
      if (store.hasModule(EXPENSE_APP_STORE_MODULE_NAME)) 
      store.unregisterModule(EXPENSE_APP_STORE_MODULE_NAME)
    })

    onMounted(() => {
       store.dispatch('app-expense/fetchExpenseTypes').then(response => {
        const expenseTypeList = Object.entries(response.data).map(item => ({ value: item[0], text: item[1] }))      
        expenseTypeItems.value = expenseTypeList   
      })     
    })
  
    const tableColumns = [  
      
      {
        text: 'MASRAF TARİHİ',
        value: 'expense_date',
        width: '180px'
      },      
      {
        text: 'MASRAF TİPİ',
        value: 'expense_type',
        width: '250px',
      },

      {
        text: 'MASRAF TUTARI',
        value: 'expense_amount',
        width: '150px',
      },
      {
        text: 'AÇIKLAMA',
        value: 'expense_desc',
        width: '400px',
      },
      {
        text: '',
        value: 'actions',
        align: 'left min-width-150',
        sortable: false,
        width: '100px',
      }      
    ]

    const expenseImageUpload = item => {   
      let randomId =  Date.now().valueOf()+(((Math.random() * Math.pow(36, 4)) | 0).toString(36));   
      item.localid=randomId
      if(item.localid !== '' )
      {
        dataUpload.value = item
        isUploadExpenseImageActive.value = true      
      }
      else
      {
        Vue.swal({
            title: 'Hata',
            text: "Local ID Bilgisi Oluşturulamadı!!",
            width: '350px',
            icon: 'error',
            background: '#FFB400',
            showCloseButton: true,
            showConfirmButton: false,
            confirmButtonColor: '#cc3d41',
          })
      }  
    }
    const saveExpenseEvent = val => {
      snackbars.value.push(val)
    }
    const saveImageEvent = val => {
      snackbars.value.push(val)
    }

    const RouteExpenseList = () => {      
      router.push({ name: 'expense-list' })
    }

    return {
      periodItems,
      periodDeger,
      icons: {mdiFactory,mdiCamera,mdiCameraOff,mdiDelete,mdiSquareEditOutline,mdiEye,mdiPlus,mdiCollage,mdiCalendar,mdiCalculatorVariant,mdiClose},
      tableColumns,
      expenseListTable,
      selectedRows ,
      loading,
      expenseType,
      expenseTypeItems,
      kaydetDurum, 
      expenseImageUpload,
      dataUpload,
      isUploadExpenseImageActive,
      saveExpenseEvent,
      saveImageEvent ,
      masrafTutariKontrol,
      masrafTipiKontrol,

      date,
      parseDate,
      formatDate,
      menu1,
      computedDateFormatted,
      dateFormatted,
      snackbars,
      RouteExpenseList,
      changePrice,
      isDark,
    
    }    
    },
    data: () => ({                
      expenseData: [{
        expense_date:  moment(String(new Date().toISOString().substr(0, 10))).format('DD-MM-YYYY') ,
        expense_type: '',
        expense_amount: 0,
        expense_desc: '',
        localid:''  ,
        dateMenu: ''
      }],  
      isLoading: false,
      search: null,
      show: false,
      loading: false ,   
      activeHover : false,
      panel: null ,
      modul:"expense",
      image_isActive:0 ,
      kaydetModu: false,

      imageSize :[
        {
          bresim:900 ,
          kresim:50 
        }
      ]
       
    }),


  methods:{  

    onChange1(value) {    
      if (this.kaydetModu) {
      return; // Eğer kayıt işlemi yapılıyorsa onChange1 çalışmasın
    }

      debugger  
      this.changePrice = true;
        if(!value || value === "0" ){
        this.kaydetDurum = true
        Vue.swal({
                title: 'Uyarı',
                html: 'Lütfen Tutar Alanını Doldurunuz',
                icon: 'warning',
                background: '#FFB400',
                timerProgressBar: true,
                showConfirmButton: false,
         })
         
      }   
      else {
    this.kaydetDurum = false;
    }     
  },

    gelen(sonuc) {
      console.log("Sonuç : "+sonuc)
      //this.kaydetDurum = sonuc
      },

    addRow: function(){                  
      let randomId =  Date.now().valueOf()+(((Math.random() * Math.pow(36, 4)) | 0).toString(36));
      this.expenseData.push({expense_amount:0,localid:randomId,expense_date:moment(String(new Date().toISOString().substr(0, 10))).format('DD-MM-YYYY') ,});   //localid:randomId  
      this.kaydetDurum=true                 
    },
    removeRow: function(item){
      Vue.swal({
        title: 'Emin misin?',
        text: 'Masrafı silme işlemini onaylayın',
        icon: 'question',
        background: '#FF4C51',
        showCloseButton: false,
        showCancelButton: true,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FF4C51',
        confirmButtonColor: '#cc3d41',
      }).then(result => {
        if (result.isConfirmed) {
          console.log("Removing", item);
          this.expenseData.splice(item.randomId, 1);
          snackbars.value.push({
            message: `Masraf Silindi`,
            color: 'error',
            timeout: 5000,
            })
        }
      })
      /* Vue.swal({
        title: 'Emin misin?',
        text: 'Silme işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
            console.log("Removing", item);
            this.expenseData.splice(item.expense_date, 1);
        }
      }) */
    },
   
    addData(){
      debugger
   
      Vue.swal({
        title: 'Emin misiniz?',
        text: 'Lütfen masraf işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {    
        if (result.isConfirmed) {  
          debugger
          this.kaydetModu = true; // Kaydet moduna geç      
          this.changePrice=false// changePrice değiştirmedi yine de hata alıyordu
          Vue.nextTick(() => {  
          for (let index = 0; index < this.expenseData.length; index++) {               
                const element = this.expenseData[index];
                const tutar = element.expense_amount 

                element.expense_amount =  new Intl.NumberFormat('tr-TR', {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  }).format(tutar)

                if(element.localid === 'undefined' || element.localid==="" || element.localid===null){
                  
                  let randomId =  Date.now().valueOf()+(((Math.random() * Math.pow(36, 4)) | 0).toString(36));         
                  this.expenseData[index].localid=randomId;
                }
                if(typeof(element.expense_amount) === 'undefined'|| element.expense_amount==="0,00"  
                          || element.expense_amount==="" || element.expense_amount===null) {
                      this.masrafTutariKontrol = 1
                      Vue.swal({
                      title: 'Uyarı',
                      html: 'Lütfen Masraf Tutarı Alanını Doldurunuz',
                      icon: 'warning',
                      background: '#FFB400',
                      timerProgressBar: true,
                      showConfirmButton: false,
                    })
                    this.expenseData[index].expense_amount=tutar
                    loading.value = false
                    return
                }
                if(typeof(element.expense_type) === 'undefined' || element.expense_type==="" || element.expense_type===null ){
                    this.masrafTipiKontrol = 1
                    Vue.swal({
                    title: 'Uyarı',
                    html: 'Lütfen Masraf Tipi Alanını Doldurunuz',
                    icon: 'warning',
                    background: '#FFB400',
                    timerProgressBar: true,
                    showConfirmButton: false,
                  })
                  loading.value = false
                  return
                }
        }   
     
          const expenseDataAdd = this.expenseData
          console.log(expenseDataAdd)  
          store
              .dispatch('postMethod', {
                method: 'expenseAdd',  
                data: JSON.stringify(expenseDataAdd)
          })
          .then(response => {
            console.log(response)
            if (response.error == 1) {
              this.kaydetDurum = false
              Vue.swal({
                title: 'Hata',
                html: response.msg,
                icon: 'error',
                background: '#FF4C51',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            } else {
              this.kaydetDurum = true
              this.kaydetModu = false; // Kayıt işlemi bittiğinde tekrar eski moda dön
              Vue.swal({
                title: 'Kayıt Başarılı',
                icon: 'success',
                timer: 2000,
                background: '#56CA00',
                timerProgressBar: true,
                showConfirmButton: false,
              })
              this.RouteExpenseList();
            }           
          })


        });
        }
        
      })
      
      
		},
    
    priceFormat: function(item){
      const index = this.expenseData.indexOf(item);          
      let nok_say=0
      let vir_say=0
      for(let karakter of this.expenseData[index].expense_amount.toString()) {
        if(karakter===".")
           nok_say+=1;
         if(karakter===",")
           vir_say+=1;
      }

      let number = (this.expenseData[index].expense_amount) //1250
      if(nok_say >1 || vir_say>0 ){
        number = (this.expenseData[index].expense_amount).replaceAll(".","") //1.254.485,54
      }
      else if ( nok_say === 1 || vir_say===0){
        number = (this.expenseData[index].expense_amount).replace(".",",") //1250.55 *binlik aracı nokta olarak girdiğinde
      }
      else if ( nok_say >= 1 || vir_say > 0){ 
        number = (this.expenseData[index].expense_amount).replace(".","") //1.455.250,55 *binlik aracı nokta olarak girdiğinde
      }

/*       var formatter = new Intl.NumberFormat('tr-TR', {
        style: 'decimal',
        minimumFractionDigits: 2,
      }); */

      let formatCurrency= currency.format(number.replace(/[^\d\.]/g, ".")) // virgül yerine nokta olmalı!
      if (formatCurrency==='NaN') {
        item.expense_amount=""
      }
      else{
        item.expense_amount= formatCurrency
      }     
      
    },
},

}
  </script>
  
  <style>
  .v-application .primary--text {
  color: var(--v-secondary-base) !important;
  caret-color: var(--v-secondary-base) !important;
}
.v-btn[disabled] {
  color: white !important; /* Yazı rengini beyaz yap */
  background-color: rgba(0, 0, 0, 0.5) !important; /* Arka plan rengini isteğe göre ayarlayın */
  cursor: not-allowed; /* İstediğiniz cursor stilini ayarlayın */
  opacity: 1; /* Opaklık ayarını 1 yaparak görünür kıl */
}
</style>
  |